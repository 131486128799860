<template>
  <div class="app-container">
    <el-table
      :data="listData"
      style="width: 100%; margin-bottom: 20px; text-align: center"
      border
    >
      <el-table-column
        prop="merchantPersonnelName"
        label="商户名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="merchantStallNo"
        label="摊位编号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="score"
        label="分数"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="scoreUser"
        label="评分人"
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      background
      v-bind="pageInfo"
      :layout="layout"
      @size-change="onSizeChange"
      @current-change="onPageChange"
    />
  </div>
</template>

<script>
import api from "@/api/common";

export default {
  data() {
    return {
      listData: [],
      handelType: "",
      dialogVisible: false,
      form: {},
      pageInfo: {
        currentPage: 1,
        pageCount: 1,
        pageSize: 15,
        total: 0,
        apiName: "/credit/marketScore",
      },
      layout: "sizes, prev, pager, next, jumper",
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      await api.list(this.pageInfo).then((res) => {
        this.listData = res.data.data;
        this.pageInfo.total = parseInt(res.data.total);
      });
    },
    async onSizeChange(e) {
      this.pageInfo.pageSize = e;
      this.getDataList();
    },
    async onPageChange(e) {
      this.pageInfo.currentPage = e;
      this.getDataList();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item label:after {
  content: "";
  display: inline-block;
  width: 100%;
}
.info-form label {
  font-weight: normal;
}
.el-form-item__label {
  text-align: justify;
  height: 50px;
}
.searchInput {
  width: 400px;
}
</style>
